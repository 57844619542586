const getHeaders = (hostname) => {
  let subdomain = hostname.split(".")[0];
  if (subdomain === "www" || subdomain === "localhost") {
    return {
      "Content-Type": "application/json",
    };
  } else {
    return {
      "Content-Type": "application/json",
      "X-Tenant-ID": subdomain,
    };
  }
};

export default getHeaders;
