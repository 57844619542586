import Image from "next/image";
import { Box, Grid, Typography } from "@mui/material";

import styles from "../../app/page.module.css";
import { PrimaryBorderButton } from "../Common/CustomButtons";
import FeaturesCard from "../Common/FeaturesCard";
import { featureList } from "./../../utils/FeaturesData";
import useWebsiteInfoStore from "@/utils/store/WebsiteInfoStore";
export default function HeroSection() {
  const { websiteInfoData } = useWebsiteInfoStore();

  return (
    <Box>
      <Grid container>
        <Grid lg={6} item={true}>
          {/* <Typography
            variant="h5"
            sx={{
              mt: { lg: "100px", xs: "0px" },
              color: "#DB2BBB",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            Learn Today And
          </Typography> */}
          {websiteInfoData ? (
            <Box>
              <Typography
                variant="h1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: { xs: "40px", lg: "60px" },
                  fontWeight: 700,
                  textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
                  marginBottom: "30px",
                  mt: { xs: 10 },
                }}
              >
                {websiteInfoData.websiteTitle}
                <Typography
                  variant="body1"
                  sx={{
                    color: "#3A3A3A",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "left",
                    mb: "15px",
                    mr: { lg: "50px" },
                  }}
                >
                  {websiteInfoData.websiteHomeDescription1}
                </Typography>
                {/* <Typography
               variant="span"
               sx={{
                 background: "#FF9B26",
                 borderRadius: "5px",
                 ml: "10px",
                 pr: "10px",
                 px: "5px",
               }}
             >
               future
             </Typography> */}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="h1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: { xs: "40px", lg: "60px" },
                  fontWeight: 700,
                  textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
                  marginBottom: "30px",
                  mt: { xs: 10 },
                }}
              >
                E Brainee Education
                <Typography
                  variant="span"
                  sx={{
                    background: "#FF9B26",
                    borderRadius: "5px",
                    ml: "10px",
                    pr: "10px",
                    px: "5px",
                  }}
                >
                  future
                </Typography>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 600,
                  mb: "15px",
                  mr: { lg: "40px" },
                }}
              >
                Your Partner for Continuous Professional Development in
                Healthcare
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 500,
                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                At ebrainee Education, we are dedicated to empowering
                healthcare workers by providing continuous professional
                development opportunities.
                <br></br>Whether you're a novice seeking mandatory training or
                an experienced professional looking to refresh your knowledge,
                our online courses are an excellent option.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 600,
                  mb: "15px",
                  mr: { lg: "40px" },
                }}
              >
                Discover the Benefits of E Brainee Education
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                - Continuous Professional Development (CPD) courses
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                - Statutory and Mandatory courses
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                - UK Core Skills Training Framework (CSTF) aligned courses
              </Typography>
            </Box>
          )}
          {/* <PrimaryBorderButton btnText="Learn more" /> */}
        </Grid>
        <Grid
          lg={6}
          item={true}
          sx={{
            marginTop: 5,

            display: { xs: "none", lg: "block" },
          }}
        >
          <Box>
            <Image
              src="/assets/images/offer.jpeg"
              alt="ebrainee"
              width={90}
              height={90}
              style={{ width: "80%", height: "100%", marginLeft: 50 }}
            />
          </Box>
          {/* <Image
            src="/assets/images/HeaderSection.svg"
            alt="ebrainee"
            width={100}
            height={100}
            style={{ width: "100%", height: "100%" }}
          /> */}
        </Grid>
      </Grid>
      <Box>
        <Box>
          {/* <Image
            src="/assets/images/stars.svg"
            className={styles.starImg}
            alt="ebrainee stars"
            width={300}
            height={300}
            style={{
              width: "400px",
              marginTop: "100px",
              height: "auto",
              position: "absolute",
              top: "450px",
              zIndex: 1,
            }}
          /> */}
        </Box>
        <Box>
          <Image
            src="/assets/images/dotedcureveline1.svg"
            alt="ebrainee stars"
            className={styles.dotCurve}
            width={100}
            height={100}
            style={{
              width: "140%",
              height: "100%",
              position: "absolute",
              top: "800px",
              left: "-200px",
              zIndex: 1,
            }}
          />
        </Box>
        <Typography
          variant="h1"
          sx={{
            color: "#3A3A3A",
            position: "relative",
            mt: { lg: "40px", xs: "80px" },
            fontSize: "48px",
            fontWeight: 700,
            textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
            my: "30px",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          Highlights Of Our Uniqueness
        </Typography>

        <Box>
          <Grid container>
            {featureList.map((feature, index) => {
              return <FeaturesCard key={index} feature={feature} />;
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
