import { getWebsiteInfo } from "@/lib/WebsiteInfoAPI";
import create from "zustand";

const useWebsiteInfoStore = create((set) => ({
  websiteInfoData: {},

  initWebsiteInfoStore: async () => {
    console.log(window.location);
    const hostname = window.location.hostname;
    let websiteData;

    if (hostname?.split(".")[0] !== "www") {
      let data = await getWebsiteInfo(hostname);
      if (data.response) {
        websiteData = data.response;
      }
    } else {
      websiteData = null;
    }
    // else {
    //   websiteData = {
    //     addressLine1: "default_addressLine1",
    //     addressLine2: "default_addressLine2",
    //     addressLine3: "default_addressLine3",
    //     country: "United States",
    //     postalCode: "12345",
    //     email: "info@example.com",
    //     mobileno: "123-456-7890",
    //     title: "Example Company",
    //     isWebsiteActive: 1,
    //     websiteTitle: "E Brainee Education future",
    //     websiteLogoUrl: "http://www.example.com/logo.png",
    //     websiteHomeHeading1: "E Brainee Education future",
    //     websiteHomeDescription1: "Welcome to our homepage.",
    //     websiteHomeHeading2: "Products",
    //     websiteHomeDescription2: "Explore our products.",
    //     websiteAboutUsHeading1: "About Us",
    //     websiteAboutUsDescription1: "Welcome to our website.",
    //     websiteAboutUsHeading2: "Our Team",
    //     websiteAboutUsDescription2: "We provide quality services.",
    //     websiteFooterMessage:
    //       "Copyright © 2024 Example Company. All rights reserved.",
    //   };
    // }

    set({ websiteInfoData: websiteData });
  },
}));

export default useWebsiteInfoStore;
