import getHeaders from "./APIHeaders";

const API = process.env.NEXT_PUBLIC_API_URL;

export async function getWebsiteInfo(hostname) {
  const headers = getHeaders(hostname);
  const res = await fetch(
    API + `/website`,
    {
      headers,
    },
    { cache: "force-cache" }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}
